import * as React from 'react'
import { map } from 'lodash'
import tw, { styled } from 'twin.macro'
import { useAnimation, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { useEffect } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import { buttonVariants, revealVariants } from '../../utils/motion'

const cards = [
  {
    content: 'Bánh tươi, hoa quả tươi',
    imageLink: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-83/2024/feaCake8324.png"
        alt="Bánh tươi, hoa quả tươi"
      />
    ),
  },
  {
    content: 'Mẫu bánh hiện đại, hương vị đặc biệt',
    imageLink: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-83/2024/feaTaste8324.png"
        alt="Mẫu bánh hiện đại, hương vị đặc biệt"
      />
    ),
  },
  {
    content: `Chuyên ship + 7 điểm nhận bánh tại Hà Nội`,
    imageLink: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-83/2024/feaShip8324.png"
        alt={`Chuyên ship + 7 điểm nhận bánh tại Hà Nội`}
      />
    ),
  },
  // {
  //   content: `Có xuất hóa đơn`,
  //   imageLink: (
  //     <StaticImage
  //       src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-20-10/2023/heartFea4.png"
  //       alt="Có xuất hóa đơn"
  //     />
  //   ),
  //   subContent: '(giá chưa gồm VAT)',
  // },
]
export default function FeatureSection() {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <motion.div
      ref={ref}
      variants={revealVariants}
      initial="hidden"
      animate={controls}
    >
      <div id="gioithieu" tw="relative pt-11 px-5 md:px-12">
        <div
          tw="lg:container mx-auto
          lg:max-w-screen-lg!"
        >
          <div tw="relative z-20">
            <h2
              tw="text-center text-4xl md:text-5xl
            text-savor-cake-pink-800
              font-savor-cake-primary-lexend font-bold
              py-3 md:px-20 xl:px-0 xl:pb-5"
            >
              Tại sao bạn nên lựa chọn Savor Cake
            </h2>
            <p
              tw="py-1 sm:py-2 md:py-6
              text-center text-base md:text-2xl
              text-20t10-red-1000 font-medium
              font-savor-cake-secondary-quicksand"
            >
              Hãy cùng tìm hiểu những điểm nổi bật của Savor Cake nhé!
            </p>
          </div>
          <CardsBackground>
            <div
              tw="lg:container
              lg:max-w-screen-lg!
              mx-auto px-2 xs:px-4 sm:px-6 md:px-8 lg:px-16 xl:px-0
              flex justify-center items-center gap-4 xxs:gap-6 md:gap-8 lg:gap-10
              z-10"
            >
              {map(cards, (card) => (
                <motion.div
                  key={card.content}
                  variants={buttonVariants}
                  whileHover="hover"
                  tw="w-1/4"
                >
                  <BaseFeatureCard>
                    <div>{card.imageLink}</div>
                    <div
                      tw="text-center font-bold uppercase
                    text-xs sm:text-sm md:text-lg lg:text-xl
                    px-1 lg:px-3 xl:px-0
                    lg:pt-4 xl:pt-0
                    font-savor-cake-secondary-quicksand
                    h-9 w-full!"
                    >
                      {card.content}
                      {card.subContent ? (
                        <p tw="font-medium text-xs sm:text-sm md:text-base">
                          {card.subContent}
                        </p>
                      ) : null}
                    </div>
                  </BaseFeatureCard>
                </motion.div>
              ))}
            </div>
          </CardsBackground>
        </div>
      </div>
    </motion.div>
  )
}

const CardsBackground = styled.div`
  position: relative;
  width: auto;
  background-image: linear-gradient(
    180deg,
    #fab6c9 0%,
    rgba(255, 230, 237, 0) 95.78%
  ) !important;
  margin: auto;
  border-radius: 20px;
  margin-top: 2rem;
  margin-bottom: 1rem;
`

const BaseFeatureCard = tw.div`
  relative
  flex flex-col justify-center items-center
  gap-y-4 md:gap-y-0 xl:gap-y-10
  md:col-span-1
  md:row-span-3 z-20
  rounded-xl
  pt-6 sm:pt-7 lg:pt-10
  pb-12 xs:pb-14 sm:pb-16 md:pb-20 lg:pb-24
  xs:px-3 sm:px-4 md:px-6 lg:px-0
`
