import 'twin.macro'
import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { reject } from 'lodash'

import { siteUrl } from '../utils/constants'
import TailwindLayout from '../components/Layouts/TailwindLayout'
import HeroSection from '../components/Banh8T3Page/HeroSection'
import PolicySection from '../components/Banh8T3Page/PolicySection'

import CollectionSection from '../components/Banh8T3Page/Collection/CollectionSection'
import FeedbackSection from '../components/Banh8T3Page/FeedbackSection'
import Footer from '../components/Base/Footer'
import SEOComponent from '../components/SEOComponent'
import CakeLocations from '../components/Locations'
import { cakeLocations, outletConfigs } from '../utils/locations'
import CartIcon from '../components/Base/CartIcon'

export default function Banh8T3Page() {
  const dataFeedback = useStaticQuery(graphql`
    {
      allS3Object(
        # filter: { Key: { regex: "/fb-banh201023/" } }
        filter: { Key: { regex: "/fb/" } }
        sort: { fields: [Key], order: ASC }
      ) {
        totalCount
        edges {
          node {
            Key
            localFile {
              name
              childrenImageSharp {
                gatsbyImageData(width: 800, height: 800)
              }
            }
          }
        }
      }
    }
  `)
  return (
    <TailwindLayout tw="bg-white">
      <CartIcon />
      <HeroSection />
      <CollectionSection />
      <PolicySection />
      <CakeLocations
        locationData={reject(cakeLocations, { code: 'S11' })}
        outletConfigs={outletConfigs}
        theme={'pink'}
        headingVariant={'pink8324'}
        pageName="2010"
        bgColor="radial-gradient(circle farthest-corner at center, #FAB6C9 0%, rgba(255, 230, 237, 0.00) 95.78%);"
        showStoreOnly={true}
      />
      <FeedbackSection data={dataFeedback} />
      <Footer bgColor="#EE6485" />
      <SEOComponent
        title="Savor Cake | Bánh kem 8/3 mừng ngày Quốc tế Phụ nữ"
        description="Lựa chọn các mẫu bánh kem nhân ngày Quốc tế Phụ nữ 8/3 chất lượng và ý nghĩa với mức giá hợp lý chỉ từ 150k."
        keywords="bánh 8/3"
        altUrl={`${siteUrl}/83`}
      />
    </TailwindLayout>
  )
}
