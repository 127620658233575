import React, { useEffect, useState } from 'react'
import 'twin.macro'
import { find, get, head } from 'lodash'
import { Link } from 'gatsby'
import { useAnimation, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import { revealVariants } from '../../../utils/motion'
import {
  DescComponent,
  SizeComponent,
  PriceComponent,
  ColLeft,
  ColRight,
  OrderButton,
  VariantStyled,
  CakeContainer,
} from '../../Banh20T10Page/Collection/CollectionElems'
import tracker from '../../../utils/tracker'
import { successToast } from '../../../utils/toastify'
import useOrderItems from '../../../hooks/useOrderItems'
import CollectionSlider from '../../Banh20T10Page/Collection/CollectionSlider'

const CollectionVariantsCake = ({
  imgData,
  products,
  variantsMap,
  reverse,
}) => {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  const { addToCart } = useOrderItems()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  // chọn màu bánh
  const [selectedProduct, setSelectedProduct] = useState(head(products))
  const [selectedVariant, setSelectedVariant] = useState(selectedProduct)

  const handleSelectedProduct = React.useCallback(
    (productId) => {
      const product = find(products, { id: productId })
      if (product) setSelectedProduct(product)
    },
    [products]
  )
  const handleSelectedVariant = React.useCallback((variant) => {
    setSelectedVariant(variant)
  }, [])
  useEffect(() => {
    setSelectedVariant(head(selectedProduct?.variants))
  }, [selectedProduct, products])

  const handleAddToCart = () => {
    tracker.addToCart({ value: selectedVariant.salePrice })
    addToCart({ product: selectedProduct, selectedVariant })
    successToast('🎉 Thêm sản phẩm vào giỏ hàng thành công')
  }

  return (
    <motion.div
      ref={ref}
      variants={revealVariants}
      initial="hidden"
      animate={controls}
    >
      <div tw="relative mb-2 z-10">
        <CakeContainer>
          <ColLeft reverse={reverse}>
            <CollectionSlider imgData={imgData} />
          </ColLeft>
          <ColRight reverse={reverse} bgColorVariant={'8324'}>
            <DescComponent product={selectedProduct} />
            {selectedVariant ? (
              <>
                {/* color variant */}
                {products.length > 1 ? (
                  <div tw="flex flex-row justify-center items-center">
                    <div tw="font-savor-cake-secondary-quicksand font-semibold text-2xl pr-2 md:text-3xl">
                      {variantsMap[selectedProduct.id]?.variantType}{' '}
                    </div>
                    {products.map((product) => (
                      <ColorVariant
                        key={product.id}
                        productId={product.id}
                        handleSelectedProduct={handleSelectedProduct}
                        selectedProduct={selectedProduct}
                      >
                        {variantsMap[product.id]?.name}
                      </ColorVariant>
                    ))}
                  </div>
                ) : null}
                {/* end color variant */}
                {selectedVariant.size ? (
                  <SizeComponent
                    product={selectedProduct}
                    selectedVariant={selectedVariant}
                    handleSelectedVariant={handleSelectedVariant}
                  />
                ) : null}
                <PriceComponent selectedVariant={selectedVariant} />
              </>
            ) : null}
            {/* <Link to={'../'}>
              <OrderButton contentActive="Xem menu" />
            </Link> */}
            <Link to={'/checkout/'} target="_blank">
              <OrderButton
                contentActive="Thêm vào giỏ"
                handleAddToCart={handleAddToCart}
              />
            </Link>
          </ColRight>
        </CakeContainer>
      </div>
    </motion.div>
  )
}

export default CollectionVariantsCake

const ColorVariant = ({
  productId,
  handleSelectedProduct,
  selectedProduct,
  children,
}) => {
  return (
    <VariantStyled
      onClick={() => {
        handleSelectedProduct(productId)
      }}
      selectedVariant={get(selectedProduct, 'id') === productId ? true : false}
    >
      {children}
    </VariantStyled>
  )
}
