import React, { useMemo } from 'react'
import { Link } from 'gatsby'
import { Icon } from 'semantic-ui-react'
import 'twin.macro'
import { useAtom } from 'jotai'
import { orderItemsAtom } from '../../../states/selectedProduct'
import { sumBy } from 'lodash'

const CartIcon = () => {
  const [orderItems] = useAtom(orderItemsAtom)
  const totalProduct = useMemo(
    () => sumBy(orderItems, 'quantity'),
    [orderItems]
  )

  return (
    <div tw="fixed z-[999] bottom-12 md:bottom-20 left-3 md:left-5">
      <Link to="/checkout/" aria-label="Cart Icon">
        <button
          type="button"
          tw="w-14 h-14 text-2xl grid
          place-content-center cursor-pointer
          border-2! border-solid border-white! p-4 font-medium text-center
          text-white bg-savor-cake-primary-green-avocado-0
          rounded-full hover:bg-savor-cake-secondary-blueberry-0
          focus:ring-4 focus:outline-none focus:ring-savor-cake-primary-green-avocado-100"
        >
          <Icon
            name="shop"
            style={{
              padding: 0,
              margin: 0,
              paddingBottom: '0.5rem',
            }}
          />
          {totalProduct !== 0 && (
            <div
              tw="absolute inline-flex items-center justify-center w-8 h-8 text-lg font-bold
            text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2 "
            >
              {totalProduct < 100 ? totalProduct : '99+'}
            </div>
          )}
        </button>
      </Link>
    </div>
  )
}
export default CartIcon
