import { styled } from 'twin.macro'
import { map } from 'lodash'
import * as React from 'react'
import { useAnimation, motion } from 'framer-motion'
import { useEffect } from 'react'
import { revealVariants } from '../../utils/motion'
import { useInView } from 'react-intersection-observer'

import headingBg from '../../assets/images/landing-page-8-3/policy-section/policyBanner2024.png'
// import tw from 'twin.macro'
import { SHIPPING_INFO_F2024 } from '../../utils/constants'
import { StaticImage } from 'gatsby-plugin-image'
const ShipPolicyContent = () => {
  const items = [
    <>
      <strong tw="text-savor-cake-pink-900">
        ✓ Đặt trước - Nhận tại địa chỉ của khách hàng:
      </strong>{' '}
      Các đơn bánh giao đến địa chỉ của khách vào 2 ngày 7/3 và 8/3 đồng giá
      ship <u>{SHIPPING_INFO_F2024.NORMAL_SHIPPING_FEE_F2024 / 1000}k/bánh</u>,
      <br />
      riêng với khung giờ cao điểm tắc đường từ <u>17-21h ngày 8/3</u> phí ship
      đồng giá <u>{SHIPPING_INFO_F2024.M8T3_SHIPPING_FEE / 1000}k/bánh</u> cho
      tất cả các đơn, khách hàng nhận bánh trong khung giờ 2 tiếng (VD:
      18h-20h).
    </>,
    <>
      <strong tw="text-savor-cake-pink-900">
        ✓ Đặt trước - Nhận tại cửa hàng:
      </strong>{' '}
      Khách có thể <u>đặt trước 24h</u> và nhận tại 1 trong 7 cửa hàng dưới đây
      để không mất phí ship (Đơn gấp nhận bánh trong vòng 24h kể từ khi đặt thì
      Savor vẫn tính phí ship bánh từ bếp đến cửa hàng 🛵)
    </>,
    <>
      <strong tw="text-savor-cake-pink-900">
        ✓ Không đặt trước - Mua tại cửa hàng:
      </strong>{' '}
      Trong 2 ngày 7 và 8/3 cửa hàng có sẵn mẫu bánh 8/3. Khách hàng vui lòng
      liên hệ điện thoại với cửa hàng hoặc đến trực tiếp để lựa bánh còn hàng
    </>,
  ]
  return (
    <>
      {items.map((item) => {
        return <div tw="mt-2">{item}</div>
      })}
    </>
  )
}
const policyItems = [
  {
    title: 'Mẫu bánh',
    description: (
      <>
        Dịp 8/3, do nhu cầu đặt bánh tăng cao nên Savor chỉ nhận 8 mẫu bánh
        trong bộ sưu tập bánh 8/3 và Socola truffle 9 viên, 12 viên.
      </>
    ),
  },
  {
    title: 'Thiệp, hoa & phụ kiện',
    description: (
      <>
        Bánh giao trong 7/3 và 8/3 được tặng kèm thiệp Happy Women’s Day và bộ
        dao dĩa giấy. <br />
        Chỉ nhận viết thiệp với đơn ship từ bếp đến địa chỉ của khách, bánh nhận
        tại cơ sở Savor không nhận viết hộ thiệp.
      </>
    ),
  },
  {
    title: 'Thanh toán',
    description:
      'Thanh toán chuyển khoản 100% đối với bánh giao trong 2 ngày 07 & 08/03.',
  },
  {
    title: 'Giao nhận hàng',
    description: <ShipPolicyContent />,
  },
]

const AbsoluteStyle = {
  position: 'absolute',
  minWidth: '100px',
  minHeight: '100px',
  height: 'auto',
  zIndex: 0,
  aspectRatio: 'auto',
}

const BackgroundImg = () => {
  const styleTop = {
    ...AbsoluteStyle,
    width: '100%',
    top: 0,
  }
  const styleBottom = {
    ...AbsoluteStyle,
    width: '100%',
    bottom: 0,
  }
  return (
    <>
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-83/2024/policy/policyBg.png"
        alt="background"
        placeholder="blurred"
        style={styleTop}
      />
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-83/2024/policy/policyBg.png"
        alt="background"
        placeholder="blurred"
        style={styleBottom}
      />
    </>
  )
}

const DetailRight = () => {
  return (
    <StaticImage
      src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-83/2024/policy/hoaPhai.png"
      alt="chi tiết nền phải"
      placeholder="blurred"
      style={{ ...AbsoluteStyle, width: '10%', top: 0, right: 0 }}
    />
  )
}
const DetailLeft = () => {
  return (
    <StaticImage
      src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-83/2024/policy/hoaTrai.png"
      alt="chi tiết nền trái"
      placeholder="blurred"
      style={{ ...AbsoluteStyle, width: '10%', top: 0, left: 0 }}
    />
  )
}

const DetailBot = () => {
  return (
    <StaticImage
      src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-83/2024/policy/hoaPolicy.png"
      alt="chi tiết nền trái"
      placeholder="blurred"
      style={{
        ...AbsoluteStyle,
        width: '12%',
        bottom: 0,
        right: 0,
      }}
    />
  )
}
const DetailBot2 = () => {
  return (
    <StaticImage
      src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-83/2024/policy/bottomCloud.png"
      alt="ảnh hoa"
      placeholder="blurred"
      style={{
        ...AbsoluteStyle,
        right: 0,
        bottom: 0,
      }}
    />
  )
}

const flowerImages = [
  <StaticImage src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-83/2024/policy/hoaMica.png" />,
  <StaticImage src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-83/2024/policy/hoaBo3.png" />,
]

export default function PolicySection() {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  return (
    <motion.div
      ref={ref}
      variants={revealVariants}
      initial="hidden"
      animate={controls}
      tw="relative z-10 overflow-hidden!"
    >
      <BackgroundImg />
      {/* hoa phụ kiện */}
      <div tw="relative">
        <DetailRight />
        <DetailLeft />
        <div
          id="hoa-ban-83"
          tw="lg:container lg:max-w-screen-lg! mx-auto
            flex flex-col gap-4 justify-center items-center
            px-4 xl:px-0
            py-6 lg:py-10 xl:py-16"
        >
          {/* title */}
          <div tw="w-2/3 lg:w-4/5 mx-auto sm:px-12 md:px-20 lg:px-32">
            <StaticImage src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-83/2024/policy/flowerSellTitle.png" />
          </div>
          {/* images */}
          <div
            tw="w-4/5 mx-auto flex gap-2 md:gap-3 lg:gap-8 justify-center items-center
              px-2 lg:px-10"
          >
            {flowerImages}
          </div>
        </div>
      </div>
      {/* Main policy */}
      <div tw="relative">
        <DetailBot />
        {/* add more detail image components here */}
        <div
          id="policy"
          tw="py-3 lg:py-6 2xl:py-8 pb-10 sm:pb-20 md:pb-28 lg:pb-32 xl:pb-44
            lg:max-w-screen-lg!
            mx-auto lg:container"
        >
          <HeadingSection
            tw="my-4 py-4
              mx-8 xs:mx-12 sm:mx-20 md:mx-32 lg:mx-36"
          >
            <h2
              tw="font-savor-cake-primary-lexend text-white text-center
              text-base sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl
              uppercase
              "
            >
              chính sách ship & thanh toán
            </h2>
          </HeadingSection>

          <div
            tw="
            bg-white w-9/10 h-full
            py-4
            shadow-md
            mx-auto relative
            xs:(after:content-[''] after:absolute
            after:w-[98%] after:h-[90%] after:-z-1
            after:bg-savor-cake-pink-900 after:rotate-[2.5deg]
            after:right-0 after:top-14 after:left-3) md:after:rotate-[7deg]
            "
          >
            <div tw="w-11/12 m-auto border-2 border-savor-cake-pink-1000 p-4 pt-8 box-border">
              <div tw="lg:container md:max-w-screen-md! mx-auto">
                {map(policyItems, (item, index) => {
                  return <PolicyItem item={item} index={index} key={index} />
                })}
              </div>
            </div>
          </div>
        </div>
        <DetailBot2 />
      </div>
    </motion.div>
  )
}

const PolicyItem = ({ item }) => {
  return (
    <div tw="flex pb-3">
      {/* For the description */}
      <div tw="pl-1">
        {/* // ban đầu dùng ngăn cách với số thứ tự */}
        <h2
          tw="p-0 m-0 text-savor-cake-pink-800 font-savor-cake-primary-lexend
          text-xl sm:text-2xl md:text-3xl
          uppercase
          "
        >
          {item.title}
        </h2>
        <p
          tw="p-0 font-savor-cake-secondary-quicksand font-medium
          text-black
          text-sm md:text-base lg:text-xl
          "
        >
          {item.description} <br />
        </p>
      </div>
    </div>
  )
}

const HeadingSection = styled.div`
  background-image: url(${headingBg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
`
