import 'twin.macro'
import * as React from 'react'
import { get, find, head } from 'lodash'
import { Link } from 'gatsby'

import { women8t3Collection } from '../../../utils/products'
import tracker from '../../../utils/tracker'
import { useAnimation, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { revealVariants } from '../../../utils/motion'
import {
  DescComponent,
  SizeComponent,
  PriceComponent,
  ColLeft,
  ColRight,
  OrderButton,
  CakeContainer,
} from '../../Banh20T10Page/Collection/CollectionElems'
import { successToast } from '../../../utils/toastify'
import useOrderItems from '../../../hooks/useOrderItems'
import CollectionSlider from '../../Banh20T10Page/Collection/CollectionSlider'

export default function CollectionSingleCake({ productId, reverse, imgData }) {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  const { addToCart } = useOrderItems()

  React.useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  const product = find(women8t3Collection, { id: productId })
  const [selectedVariant, setSelectedVariant] = React.useState(
    head(get(product, 'variants'))
  )
  const handleSelectedVariant = (variant) => {
    setSelectedVariant(variant)
  }

  const handleAddToCart = () => {
    tracker.addToCart({ value: selectedVariant.salePrice })
    addToCart({ product, selectedVariant })
    successToast('🎉 Đã thêm sản phẩm vào giỏ')
  }
  return (
    <motion.div
      ref={ref}
      variants={revealVariants}
      initial="hidden"
      animate={controls}
    >
      {product && (
        <div tw="relative pb-2">
          <CakeContainer>
            <ColLeft reverse={reverse}>
              <CollectionSlider imgData={imgData} />
            </ColLeft>
            <ColRight reverse={reverse} bgColorVariant={'8324'}>
              <DescComponent product={product} />
              <SizeComponent
                product={product}
                selectedVariant={selectedVariant}
                handleSelectedVariant={handleSelectedVariant}
              />
              <PriceComponent selectedVariant={selectedVariant} />

              <Link to={'/checkout/'} target="_blank">
                <OrderButton
                  sku={selectedVariant.sku}
                  contentActive="Thêm vào giỏ"
                  handleAddToCart={handleAddToCart}
                />
              </Link>
            </ColRight>
          </CakeContainer>
        </div>
      )}
    </motion.div>
  )
}
