import React, { useEffect } from 'react'
import 'twin.macro'
import { StaticImage } from 'gatsby-plugin-image'
import { useAnimation, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { filter, includes } from 'lodash'
import { graphql, useStaticQuery } from 'gatsby'

import { revealVariants } from '../../../utils/motion'
import useMediaQuery from '../../../hooks/useMediaQuery'
import { women8t3Collection } from '../../../utils/products'
// components
import CollectionSingleCake from './CollectionSingleCake'
import CollectionVariantsCake from './CollectionVariantsCake'
import FeatureSection from '../FeatureSection'
import BannerTextIcons from '../../Base/TitleBanner/BannerTextIcons'

const AbsoluteStyle = {
  position: 'absolute',
  minWidth: '100px',
  minHeight: '100px',
  height: 'auto',
  zIndex: 0,
  aspectRatio: 'auto',
  overflow: 'hidden',
}
const BackgroundImg = ({ isBreakPoint }) => {
  const styleTop = { ...AbsoluteStyle, width: '100%', top: 0 }
  const styleBottom = { ...AbsoluteStyle, width: '100%', bottom: 0 }
  return (
    <div tw="overflow-hidden!">
      {isBreakPoint ? (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-83/2024/mainBg.png"
          alt="background"
          placeholder="blurred"
          style={styleTop}
        />
      ) : (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-83/2024/mainBgMobi.png"
          alt="background"
          placeholder="blurred"
          style={styleTop}
        />
      )}
      {isBreakPoint ? (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-83/2024/mainBg.png"
          alt="background"
          placeholder="blurred"
          style={styleBottom}
        />
      ) : (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-83/2024/mainBgMobi.png"
          alt="background"
          placeholder="blurred"
          style={styleBottom}
        />
      )}
    </div>
  )
}

const FlowerLeft = (
  <StaticImage
    src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-83/2024/flowerLeft.png"
    alt="ảnh banner"
    placeholder="blurred"
  />
)
const FlowerRight = (
  <StaticImage
    src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-83/2024/flowerRight.png"
    alt="ảnh banner"
    placeholder="blurred"
  />
)
const LoveLeft = (
  <StaticImage
    src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-83/2024/loveLeft.png"
    alt="ảnh banner"
    placeholder="blurred"
  />
)
const LoveRight = (
  <StaticImage
    src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-83/2024/loveRight.png"
    alt="ảnh banner"
    placeholder="blurred"
  />
)
const PartyLeft = (
  <StaticImage
    src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-83/2024/partyLeft.png"
    alt="ảnh banner"
    placeholder="blurred"
  />
)
const PartyRight = (
  <StaticImage
    src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-83/2024/partyRight.png"
    alt="ảnh banner"
    placeholder="blurred"
  />
)
// const ChocoLeft = (
//   <StaticImage
//     src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-83/2024/chocoLeft.png"
//     alt="ảnh banner"
//     placeholder="blurred"
//   />
// )
// const ChocoRight = (
//   <StaticImage
//     src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-83/2024/chocoRight.png"
//     alt="ảnh banner"
//     placeholder="blurred"
//   />
// )

const CollectionSection = (imageSliderData) => {
  const query = graphql`
    {
      imgDataHoaHongNuHoang: allS3Object(
        filter: {
          Key: {
            regex: "/images/products/banh-sinh-nhat/banh-kem-hoa-hong-nu-hoang/"
          }
        }
        sort: { fields: [Key], order: ASC }
      ) {
        totalCount
        edges {
          node {
            Key
            localFile {
              name
              childrenImageSharp {
                gatsbyImageData(width: 800, height: 900)
              }
            }
          }
        }
      }
    }
  `
  const { imgDataHoaHongNuHoang } = useStaticQuery(query)

  //   produc†s
  const tulipCakes =
    filter(women8t3Collection, (product) => {
      return product.id === 'banh-kem-bo-hoa-tulip-womens-day'
    }) || []
  const setMousses =
    filter(women8t3Collection, (product) => {
      return includes(
        [
          'set-mousse-trang-tri-hoa-qua-tuoi-set-9',
          'set-mousse-trang-tri-hoa-qua-tuoi-set-25',
        ],
        product.id
      )
    }) || []
  const fruitCakes =
    filter(women8t3Collection, (product) => {
      return product.id === 'banh-kem-hoa-qua-nhiet-doi'
    }) || []

  const collectionVariantsMap = {
    'banh-kem-bo-hoa-tulip-womens-day': {
      name: null, // 'Hồng'
    },
    'banh-kem-hoa-qua-nhiet-doi': {
      name: null,
    },
    'set-mousse-trang-tri-hoa-qua-tuoi-set-9': {
      name: 'Set 9',
      variantType: 'Set',
    },
    'set-mousse-trang-tri-hoa-qua-tuoi-set-25': {
      name: 'Set 25',
      variantType: 'Set',
    },
    'truffle-9v-2024': {
      name: 'Hộp 9',
      variantType: 'Hộp',
    },
    'truffle-12v-2024': {
      name: 'Hộp 12',
      variantType: 'Hộp',
    },
  }

  // animation
  const controls = useAnimation()
  const [ref, inView] = useInView()
  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  const bgBreakPoint = useMediaQuery('(min-width: 768px)')

  return (
    <div tw="relative z-10 overflow-hidden">
      <BackgroundImg isBreakPoint={bgBreakPoint} />
      <FeatureSection />
      <motion.div
        ref={ref}
        variants={revealVariants}
        initial="hidden"
        animate={controls}
      >
        <div tw="relative mb-10 z-10" id="collection">
          <div
            tw="relative z-20 max-w-4xl mx-2 sm:mx-5
            lg:mx-auto text-center space-y-6 pb-6
            md:space-y-10 md:pb-10
            sm:pt-8"
          >
            <h1 tw="font-savor-cake-primary-lexend font-bold text-4xl md:text-5xl text-savor-cake-pink-900">
              Bộ sưu tập bánh kem 8/3
            </h1>
            <p
              tw="font-savor-cake-secondary-quicksand font-medium text-sm md:text-2xl
            text-20t10-red-1000 px-6 md:px-40"
            >
              Savor Cake ra mắt bộ sưu tập bánh cực xinh xắn nhân dịp Ngày Phụ
              Nữ Việt Nam
            </p>
            {/* bánh hoa */}
            <BannerTextIcons
              groupTitle={'Flower Forest'}
              additionTitle={'Bánh kem bó hoa 8/3'}
              positionVariant="overTop8324"
              leftBannerIcon={FlowerLeft}
              rightBannerIcon={FlowerRight}
              textVariant="pink"
              additionTextVariant={'pink'}
              iconLeftSizeVariant="flower83"
              iconRightSizeVariant="flower83"
            />
            <CollectionProduct
              productId={'banh-kem-hoa-hong-nu-hoang'}
              imgData={imgDataHoaHongNuHoang}
            />
            <CollectionProduct
              productId={'banh-kem-hoa-cam-chuong-chen-hong'}
              // imgData={imgDataHoaCamChuong}
              reverse={true}
            />
            <CollectionProduct
              isSingle={false}
              // imgData={imgDataTulipWomen}
              products={tulipCakes}
              variantsMap={collectionVariantsMap}
            />
            {/* love */}
            <BannerTextIcons
              groupTitle={'Red for lover'}
              additionTitle={'Bánh red velvet 8/3'}
              positionVariant="overTop8324"
              leftBannerIcon={LoveLeft}
              rightBannerIcon={LoveRight}
              textVariant="pink"
              additionTextVariant={'pink'}
              iconLeftSizeVariant="love83"
              iconRightSizeVariant="love83"
            />
            <CollectionProduct
              // imgData={imgDataRedVelvelTron}
              productId={'banh-red-velvet-tron-dau-da-lat'}
              reverse={true}
            />
            <CollectionProduct
              productId={'banh-kem-red-velvet-trai-tim-womens-day'}
              // imgData={imgDataRedVelvetWomen}
            />
            {/* party */}
            <BannerTextIcons
              groupTitle={"Women's Day Party"}
              additionTitle={'Bánh sự kiện 8/3'}
              positionVariant="overTop8324"
              leftBannerIcon={PartyLeft}
              rightBannerIcon={PartyRight}
              textVariant="pink"
              additionTextVariant={'pink'}
              iconLeftSizeVariant="party83"
              iconRightSizeVariant="party83"
              mainSizeVariant={'smallerMobi'}
            />
            <CollectionProduct
              isSingle={false}
              products={setMousses}
              variantsMap={collectionVariantsMap}
              // imgData={imgDataSetMousse}
              reverse={true}
            />
            <CollectionProduct
              isSingle={false}
              products={fruitCakes}
              variantsMap={collectionVariantsMap}
              // imgData={imgDataFruit}
            />
            {/* socola */}
            {/* <BannerTextIcons
              groupTitle={'Choco Love'}
              additionTitle={'Truffle Chocolate 8/3'}
              positionVariant="overTop8324"
              leftBannerIcon={ChocoLeft}
              rightBannerIcon={ChocoRight}
              textVariant="pink"
              additionTextVariant={'pink'}
              iconLeftSizeVariant="choco83"
              iconRightSizeVariant="choco83"
            />
            <CollectionProduct
              isSingle={false}
              products={truffleSocolas}
              variantsMap={collectionVariantsMap}
              imgData={imgDataTruffle}
              reverse={true}
            /> */}
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export default CollectionSection

const CollectionProduct = ({
  isSingle = true,
  imgData,
  productId,
  reverse,
  products,
  variantsMap,
}) => {
  return isSingle ? (
    <CollectionSingleCake
      imgData={imgData}
      productId={productId}
      reverse={reverse}
    />
  ) : (
    <CollectionVariantsCake
      products={products}
      variantsMap={variantsMap}
      imgData={imgData}
      productId={productId}
      reverse={reverse}
    />
  )
}
