import React from 'react'
import tw from 'twin.macro'
import { StaticImage } from 'gatsby-plugin-image'
import { containerStyle, productGroupTitleWithIconStyle } from '../index'

const TEXT_VARIANTS = {
  white: tw`text-white`,
  pink: tw`text-savor-cake-pink-900`,
}

const ADDITION_TITLE_COLOR_VARIANTS = {
  white: tw`text-white`,
  pink: tw`text-savor-cake-pink-900`,
}
const POSITION_VARIANTS = {
  center: tw`top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`,
  overTop: tw`top-[52%] md:top-[45%] left-1/2 -translate-x-1/2 -translate-y-1/2`,
  overTop8324: tw`top-[45%] left-1/2 -translate-x-1/2 -translate-y-1/2`,
}
const ADDITION_TITLE_SIZE_VARIANTS = {
  bigger: tw`text-3xl md:text-4xl lg:text-5xl`,
  smaller: tw`text-base xs:text-lg sm:text-xl md:text-2xl lg:text-3xl`,
}
const ICON_LEFT_SIZE_VARIANTS = {
  shorter: tw`w-[28%] pl-12 pb-10`,
  flower83: tw`w-[28%] pb-8 pl-3 sm:pl-10 lg:pl-12 sm:pb-10`,
  love83: tw`w-1/3 pl-3 sm:pl-5`,
  party83: tw`w-1/3 md:w-[30%]`,
  choco83: tw`w-1/3`,
}
const ICON_RIGHT_SIZE_VARIANTS = {
  shorter: tw`w-1/3 pr-3`,
  flower83: tw`w-[33%] -mb-2 pr-2 xs:pr-3 sm:pr-6 lg:pr-1 sm:pb-10 md:pb-12 sm:self-end sm:mb-0`,
  love83: tw`w-1/3 pr-3 sm:pr-5`,
  party83: tw`w-1/3 md:w-[30%]`,
  choco83: tw`w-1/3`,
}

const defaultBannerImage = (
  <StaticImage
    src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-83/2024/titleBanner83.png"
    alt="Ảnh banner"
    placeholder="blurred"
  />
)

const TitleHeading = ({
  groupTitle,
  additionTitle,
  textVariant = 'white',
  additionTextVariant = 'white',
  additionSizeVariant = 'smaller',
  // mainSizeVariant = 'base',
}) => {
  return (
    <h2
      css={[
        tw`w-full uppercase font-bold`,
        productGroupTitleWithIconStyle,
        TEXT_VARIANTS[textVariant],
        additionTitle && tw`leading-none! md:leading-tight!`,
      ]}
    >
      {groupTitle}
      {additionTitle ? (
        <span
          css={[
            productGroupTitleWithIconStyle,
            tw`block font-semibold normal-case`,
            ADDITION_TITLE_COLOR_VARIANTS[additionTextVariant],
            ADDITION_TITLE_SIZE_VARIANTS[additionSizeVariant],
          ]}
        >
          {additionTitle}
        </span>
      ) : null}
    </h2>
  )
}

const BannerTextIcons = ({
  bannerImage,
  leftBannerIcon,
  rightBannerIcon,
  groupTitle,
  additionTitle,
  textVariant,
  additionTextVariant,
  additionSizeVariant,
  positionVariant = 'center',
  iconLeftSizeVariant = 'shorter',
  mainSizeVariant,
}) => {
  return (
    <div tw="relative w-full py-1" css={containerStyle}>
      {bannerImage || defaultBannerImage}
      {leftBannerIcon || rightBannerIcon ? (
        <div
          tw="w-full flex justify-between items-center
          absolute z-10
          xs:px-1 md:px-2"
          css={POSITION_VARIANTS[positionVariant]}
        >
          <div css={ICON_LEFT_SIZE_VARIANTS[iconLeftSizeVariant]}>
            {leftBannerIcon}
          </div>
          <TitleHeading
            groupTitle={groupTitle}
            additionTitle={additionTitle}
            textVariant={textVariant}
            additionTextVariant={additionTextVariant}
            additionSizeVariant={additionSizeVariant}
            mainSizeVariant={mainSizeVariant}
          />
          <div css={ICON_RIGHT_SIZE_VARIANTS[iconLeftSizeVariant]}>
            {rightBannerIcon}
          </div>
        </div>
      ) : (
        <TitleHeading
          groupTitle={groupTitle}
          additionTitle={additionTitle}
          textVariant={textVariant}
          additionTextVariant={additionTextVariant}
          additionSizeVariant={additionSizeVariant}
          mainSizeVariant={mainSizeVariant}
        />
      )}
    </div>
  )
}

export default BannerTextIcons
