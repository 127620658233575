import tw from 'twin.macro'
import { map } from 'lodash'
import * as React from 'react'
import { Link } from 'react-scroll'
import { useEffect, useRef } from 'react'
import { Icon } from 'semantic-ui-react'
import { StaticImage } from 'gatsby-plugin-image'

export default function TopMenu({ menuItems }) {
  const [showMenu, setShowMenu] = React.useState(false)

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowMenu(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  return (
    <div tw="bg-savor-cake-pink-800">
      <div
        tw="
        grid grid-cols-2
        py-3 md:py-6
        px-4 pr-20 lg:px-10 lg:pr-10
        lg:gap-20
        lg:container mx-auto
        xl:max-w-screen-xl
        lg:flex lg:justify-between lg:items-center
        "
      >
        {/* menu mobile icon */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          tw="order-first text-xl lg:hidden self-center"
          role="button"
          tabindex="-1"
          onClick={() => {
            setShowMenu(!showMenu)
          }}
        >
          <Icon
            className="large"
            tw="text-white"
            name={showMenu ? 'close' : 'bars'}
          ></Icon>
        </div>
        {/* logo */}
        <div
          tw="justify-self-start w-16
          lg:order-first lg:justify-self-start
          lg:w-22 xl:w-36 2xl:w-56"
        >
          <StaticImage
            src="https://s3.kstorage.vn/savor-web/src/assets/images/logos/savor-cake-logo-white.png"
            alt="logo"
            width={80}
          />
        </div>
        {/* list mobile */}
        <ul
          tw="lg:hidden flex flex-col gap-10 align-bottom "
          css={[
            showMenu
              ? tw`block text-white pl-2 text-lg font-thin z-10`
              : tw`hidden`,
          ]}
        >
          {map(menuItems, (item) => (
            <li key={item.id} tw="p-1 w-full">
              {item.url ? (
                <a
                  href={item.url}
                  css={mobiLiStyle}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.label}
                </a>
              ) : (
                <Link
                  href={item ? item.id : null}
                  spy={true}
                  to={item.id}
                  smooth={true}
                  css={mobiLiStyle}
                >
                  {item.label}
                </Link>
              )}
            </li>
          ))}
        </ul>
        {/* list desktop */}
        <ul tw="hidden lg:flex lg:gap-2 lg:justify-between">
          {map(menuItems, (item) => (
            <li
              tw="justify-self-center self-center text-center text-base
              font-savor-cake-secondary-quicksand text-white! font-bold
              md:text-lg lg:text-xl
              px-4"
              key={item.id}
            >
              {item.url ? (
                <a
                  href={item.url}
                  css={desktopLiStyle}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.label}
                </a>
              ) : (
                <Link
                  href={item ? item.id : null}
                  to={item.id}
                  smooth={true}
                  css={desktopLiStyle}
                >
                  {item.label}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

const desktopLiStyle = tw`hover:text-savor-cake-pink-400 text-white cursor-pointer`
const mobiLiStyle = tw`py-2 px-5 font-savor-cake-secondary-quicksand
cursor-pointer leading-tight
hover:bg-lava-yellow-200 hover:text-savor-cake-pink-400
text-white pl-3 w-full block`
